<script lang="ts">
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import blockState from '$src/lib/stores/block-state'
	import DOMPurify from 'dompurify'
	import { createEventDispatcher } from 'svelte'
	const dispatch = createEventDispatcher()
	export let title: string
	export let description: string | null = null
	export let size: 'small' | 'large' = 'small'
	export let iconUrl: string = '/visuals/icons/fork-knife.svg'
	export let bgColor: string = 'bg-red-200'
	export let imageUrl: string | null = null
	export let placeId: string | null = null
	export let isClickable: boolean = true
	export let isTruncated: boolean = true

	$: sanitizedDescription = DOMPurify.sanitize(fixTitleCharacters(description), {
		ALLOWED_TAGS: [],
		ALLOWED_ATTR: [],
		KEEP_CONTENT: true,
	})
		?.trim()
		.slice(0, 100)

	$: fixedTitle = fixTitleCharacters(title)

	function handleClick() {
		dispatch('click')
	}

	function handleSetHoverState() {
		if (placeId != null) blockState.setHoveringPlaceId(placeId)
	}

	function handleUnsetHoverState() {
		if (placeId != null) blockState.cleartHoveringPlaceId()
	}
</script>

<button
	disabled={!isClickable}
	on:click={handleClick}
	on:mouseenter={handleSetHoverState}
	on:mouseleave={handleUnsetHoverState}
	class={`flex items-center  ${
		isClickable ? 'group cursor-pointer' : 'cursor-not-allowed'
	}  w-full py-1 overflow-x-hidden truncate mr-4`}
>
	<div
		class={`${
			size === 'large' ? 'w-12 h-12' : 'w-8 h-8'
		} flex items-center justify-center rounded-md object-cover flex-shrink-0 ${bgColor}`}
	>
		{#if imageUrl != null}
			<img
				class={`${size === 'large' ? 'w-12 h-12' : 'w-8 h-8'} rounded-md image-cover`}
				src={imageUrl}
				alt={title}
			/>
		{:else}
			<img class={`${size === 'large' ? 'w-6 h-6' : 'w-5 h-5'} p-auto`} src={iconUrl} alt={title} />
		{/if}
	</div>
	<div class="pl-4 text-start w-full">
		<div
			class={`text-sm font-medium dark:text-brand-gray-2 group-hover:dark:text-white text-black w-full ${
				isTruncated ? 'truncate pr-8' : 'whitespace-break-spaces'
			}`}
		>
			{@html fixedTitle}
		</div>
		<p
			class={`text-xs dark:text-brand-gray-3 group-hover:dark:text-brand-gray-2 text-brand-gray-5 w-full ${
				isTruncated ? 'truncate pr-8' : 'whitespace-break-spaces'
			}`}
		>
			{@html sanitizedDescription ?? 'No description available'}
		</p>
	</div>
</button>
